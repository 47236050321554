.contact-block {
  padding: 2rem;
  background-color: #3538436e;
  color: #fff;
  text-align: center;
}

.underline-orange {
  border-bottom: 5px solid orange;
  border-radius: 10px;
  width: fit-content;
  margin: auto;
}

.contact-form {
  width: 75%;
  margin: 2rem auto;
  padding: 1rem;
  border-radius: 10px;
  display: flex;
}

.background-white {
  background-color: #fff;
}

.inputField {
  width: 100%;
}

.textArea {
  width: 100%;
  height: 10rem;
  border: 1px solid #bfbaba;
  border-radius: 5px;
}

.textArea:hover,
.textArea:focus {
  outline: none;
  border: 1px solid black;
}

.textArea:focus {
  border: 2px solid #0080ff;
}

.input-margin {
  margin: 1rem;
}

.form-cont {
  min-width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.w-100 {
  width: fit-content;
  align-self: flex-end;
  margin-right: 15px !important;
}

.contact-right {
  color: black;
  text-align: justify;
  height: 100%;
  margin: auto;
  background-color: #0080ff32;
  padding: 1rem;
}

.location-map {
  width: 100%;
}

@media only screen and (max-width: 840px) {
  .contact-block {
    padding: 1rem 0rem;
  }

  .underline-orange {
    border: none;
  }

  .contact-form {
    flex-direction: column;
    margin: auto;
    padding: 0;
    width: 90%;
  }

  .location-map {
    width: auto;
    height: 50dvh;
    margin: 1.5rem 0.5rem;
  }
}