.banner {
  position: relative;
  height: 100dvh;
}

.background-video {
  z-index: -1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}

.banner-body-content-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 0;
  margin-left: 2rem;
  height: 100%;
  width: auto;
  color: white;
  flex-direction: column;
  text-align: left;
}

.banner-address {
  width: 60%;
  text-align: left;
}

.font-big {
  width: 40%;
  text-align: left;
  font-weight: 400;
  font-size: 60px;
  font-style: normal;
  color: #e8e1dc;
  line-height: 70px;
  text-decoration: none;
  transform: none;
  font-family: ivypresto-display;
  letter-spacing: 0;
}

.button-home-tour {
  margin-top: 20px;
  background-color: #35342b;
  padding: 15px 20px;
  margin-right: 10px;
  color: white;
  border: none;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .banner-body-content-container {
    width: 75dvw;
    margin: auto;
    align-items: center;
  }

  .banner-address {
    width: 100%;
    text-align: center;
  }
  .fs-18 {
    font-size: 16px;
  }
  .font-big {
    width: 100%;
    font-size: 36px;
    line-height: 41px;
    text-align: center;
  }
}
