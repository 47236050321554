@import url("https://fonts.googleapis.com/css2?family=Charmonman&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

.app-body {
  max-width: 100vw;
  min-height: 90vh;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.cursor-pointer {
  cursor: pointer;
}

.d-flex-just-center {
  display: flex;
  justify-content: center;
}

.d-flex-align-center {
  display: flex;
  align-items: center;
}

.d-flex-just-align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.d-flex-just-align-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
}

.d-flex-just-btwn-align-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flexWrapColumn {
  flex-wrap: wrap;
  flex-direction: row;
}
.normal-text-link {
  color: inherit;
  text-decoration: none;
}

.normal-text-link:hover {
  text-decoration: underline;
}

.padding-40 {
  padding: 40px;
}

.text-center {
  text-align: center;
}

.font-Montserrat {
  font-family: "Montserrat", "Courier New", Courier, monospace;
}

@media only screen and (max-width: 640px) {
  .hide-in-mobile {
    display: none;
  }
}

.rotate-animation {
  animation-name: grow-shrink;
  animation-duration: 1.5s;
}

@keyframes grow-shrink {
  0%{
    transform: scale(0);
    rotate: 0deg;
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
    rotate: 360deg;
  }
}

.fade-in-animation {
  animation-name: fade-in-right;
  animation-duration: 2s;
}

@keyframes fade-in-right {
  from {
    opacity: 0;
    transform: translateX(-15px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}


.grow-left-animation {
  animation-name: grow-left;
  animation-duration: 2s;
}

@keyframes grow-left {
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
}

.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 1000ms ease-out, transform 500ms ease-out,
    visibility 1000ms ease-out;
  will-change: opacity, transform, visibility;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.floater-animation{
  transform: translateY(-2%);
  animation: floater 5s infinite;
}

@keyframes floater {
  0%{transform: translateY(-2%);transition: ease 0.5s;}
  50%{transform: translateY(2%);transition: ease 0.5s;}
}

@media only screen and (max-width: 768px) {
  .floater-animation{
    transform: translateY(0);
    animation: none;
  }
}

.text-typo-sub-text-no-padding {
  font-family: "Charmonman", cursive !important;
  font-size: 82px;
  font-weight: 1000 !important;
  padding: 0 1rem;
  color: #000;
}