.hero-container-life-time-quality {
  background-color: #f9f9f9;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 2rem;
  margin: 2rem 0;
}

.typo-container-life-time-quality {
  width: 40%;
  text-align: justify;
}

.image-block{
  width: 60%;
}

.image-block img {
  width: 100%;
  height: 75%;
}

.quality-typo-life-time-quality {
  margin: 25px;
  font-family: Montserrat;
  font-size: 16px;
  text-align: justify;
}

@media only screen and (max-width: 1000px) {
  .hero-container-life-time-quality {
    flex-direction: column;
    height: auto;
    width: auto;
    padding: 1rem;
  }
  .quality-typo-life-time-quality {
    text-align: center;
  }
  .typo-container-life-time-quality {
    width: auto;
    text-align: center;
  }
  
.image-block{
  width: 100%;
}
  .image-block img {
    width: 100%;
    height: 100%;
  }
  
}
