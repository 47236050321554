.text-typo-heading-1 {
  font-family: ivypresto-display;
  font-size: 50px;
  font-weight: 100;
  text-transform: uppercase;
  color: #000;
}
.text-typo-sub-text {
  font-family: "Charmonman", cursive;
  font-size: 82px;
  font-weight: 1000;
  padding: 0 1rem;
  padding-left: 100px;
  color: #000;
}

.lightClr {
  color: #fff;
}
@media only screen and (max-width: 600px) {
  .text-typo-heading-1 {
    font-size: 30px;
  }
  .text-typo-sub-text {
    font-size: 35px;
    text-align: end;
    padding-left: 0;
  }
}
