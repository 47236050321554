.here-from-us-main-content {
  margin: 2rem;
  padding: 1.4rem;
  /* background-color: #facc6b;
  background-image: linear-gradient(315deg, #facc6b 0%, #fabc3c 74%); */
  background-color: #f06543;
background-image: linear-gradient(315deg, #f06543, #ffbe3d);
  color: #fff !important;
  font-family: "Montserrat";
  border-radius: 8px;
  width: 25dvw;
  height: 460px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.here-from-us-main-content .icon{
  width: 25px;
  height: 25px;
}

.font-comments {
  color: #fff;
  margin: 1.2rem auto;
}
.hero-comments-container {
  /* margin: 2rem 0; */
  padding: 2rem;
  background-color: #fbfbfb;
  margin-bottom: 25px;
}
.comments-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.here-from-us-main-content:hover {
  transition:  0.1s transform ease-in-out;
  transform: scale(1.1);
}
@media only screen and (max-width: 920px) {
  .comments-container {
    display: flex;
    flex-direction: column;
  }
  .hero-comments-container {
    height: auto;
    width: auto;
    padding: 1rem;
  }
  .here-from-us-main-content {
    margin: 1rem;
    width: auto;
    height: auto;
  }
  .here-from-us-main-content:hover {
    transform: none;
  }
}
