.center-image-blog {
  margin-top: 7%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 45dvw;
  height: 50dvh;
  border-radius: 5px;
}

@media only screen and (max-width: 1200px) {
  .center-image-blog {
    margin-top: 18%;
    width: 75dvw;
    height: 45dvh;
  }
}
